import React, { useState, useEffect } from 'react'
import { Header } from 'semantic-ui-react'
// Components
import {
  FilterSortMenu,
  Loading,
  ProductCard,
  Seo,
  ShoppingWithBanner,
} from '../components'
// Styles
import { ProductsGrid } from '../styles/LandingPage.styles'
// Context
import { useStoreContext } from '../context/StoreContext'
import { usePrismic } from '../context/PrismicContext'
import { useCartContext } from '../context/CartContext'
import {filterProducts} from "../utils/productHelpers";

const defaultFilterState = {
  filterBy: '',
  option: '',
  showFiltered: false,
}

const ProductsPage = () => {
  const [productList, setProductList] = useState([])
  const [filterByName, setFilterByName] = useState(null)
  const [{ option, filterBy, showFiltered }, setState] =
    useState(defaultFilterState)

  const {
    prismicData: {
      prismicProductsPage: {
        by_type,
        check_out_our_products,
        loading_products,
        products,
      },
    },
  } = usePrismic()

  const { storeData, isStoreLoading } = useStoreContext()
  const { isPcOfferInCart, isAmbOfferInCart } = useCartContext()

  const allProducts = filterProducts(storeData['all-products']?.products ?? [], null, isPcOfferInCart, isAmbOfferInCart)
  const benefitOptions = storeData['benefit']?.subCategories ?? []
  let typeOptions = []
  const tempOptArray = Object.entries(storeData['type']?.subCategories ?? {})
  if(tempOptArray.length !== 0) {
    typeOptions = Object.fromEntries(tempOptArray.filter(([key, val]) => {
      if(!isPcOfferInCart && !isAmbOfferInCart) {
        return key !== 'packs'
      }
      return true
    }))
  }

  const handleShowProducts = (value, text) => {
    if (value === '') {
      setState(defaultFilterState)
      setProductList(allProducts)
    }
    setState({
      option: text,
      filterBy: value,
      showFiltered: true,
    })
  }

  useEffect(() => {
    if (!filterBy) return
    if (option === by_type[0].text) {
      setProductList(typeOptions[filterBy]?.products)
      setFilterByName(typeOptions[filterBy]?.name)
    } else {
      setProductList(benefitOptions[filterBy]?.products)
      setFilterByName(benefitOptions[filterBy]?.name)
    }
  }, [filterBy])

  if (isStoreLoading)
    return <Loading loading={true} message={loading_products[0].text} />

  return (
    <>
      <Seo title={products[0].text} />
      <ShoppingWithBanner />
      <FilterSortMenu
        types={typeOptions}
        benefits={benefitOptions}
        handleFilter={handleShowProducts}
      />
      <Header style={{ marginTop: '1em' }} textAlign="center" size="huge">
        {!filterBy ? check_out_our_products[0].text : `${filterByName}`}
      </Header>
      {showFiltered ? (
        <ProductsGrid>
          {productList?.map(product => (
            <ProductCard key={product.uid} {...product} />
          ))}
        </ProductsGrid>
      ) : (
        <ProductsGrid>
          {allProducts?.map(product => (
            <ProductCard key={product.uid} {...product} />
          ))}
        </ProductsGrid>
      )}
    </>
  )
}

export default ProductsPage
